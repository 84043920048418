/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Introducing the Palm Beach 85: A New Era of Luxury Yachting"), "\n", React.createElement(_components.p, null, "Palm Beach Motor Yachts has unveiled its latest masterpiece, the Palm Beach 85 (PB85), setting a new benchmark in the realm of luxury yachting. As the new flagship yacht of the esteemed Australian yacht builder, the PB85 epitomizes the pinnacle of nautical engineering and design. This 27.6-meter luxury motor yacht is crafted to exceed the highest expectations in luxury, onboard comfort, and performance, marking a significant milestone for Palm Beach Motor Yachts as it becomes the largest yacht in their illustrious fleet."), "\n", React.createElement(_components.h2, null, "Unparalleled Luxury and Performance"), "\n", React.createElement(_components.p, null, "The Palm Beach 85 is not just a testament to the builder's commitment to excellence but also a reflection of their dedication to pushing the boundaries of what is possible in yacht design. While specific details about the yacht's features and capabilities remain highly anticipated, the PB85 is expected to showcase an array of innovative design elements and state-of-the-art technologies that will redefine the standards of luxury yachting."), "\n", React.createElement(_components.h2, null, "Discover More About Palm Beach Motor Yachts"), "\n", React.createElement(_components.p, null, "For enthusiasts eager to delve deeper into the world of luxury motor yachts and learn more about the Palm Beach 85, Palm Beach Motor Yachts' official website offers a wealth of information. From detailed specifications of their current fleet to insights into their unparalleled craftsmanship, the website serves as a comprehensive resource for those passionate about the luxury yachting lifestyle."), "\n", React.createElement(_components.p, null, "Visit ", React.createElement(_components.a, {
    href: "https://www.palmbeachmotoryachts.com/"
  }, "Palm Beach Motor Yachts"), " to explore the epitome of luxury yachting and stay updated on the latest developments surrounding the Palm Beach 85."), "\n", React.createElement(_components.p, null, "As we await further details about this magnificent yacht, the Palm Beach 85 already promises to be a game-changer in the luxury yachting world, embodying the perfect blend of elegance, performance, and unparalleled luxury that Palm Beach Motor Yachts is renowned for."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
